<template>
  <div class="table-container">
    <div class="table-headers pa-4 elevation-2">
      <v-row>
        <v-col sm="6" md="3" cols="12">
          <v-text-field
            label="Search by email"
            outlined
            dense
            hide-details="auto"
            v-model="searchEmail"
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <v-select
            :items="['Active', 'Inactive']"
            label="Status"
            outlined
            dense
            clearable
            hide-details="auto"
            v-model="filterStatus"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col lg="6" md="6" cols="12" class="table-header-buttons">
          <v-btn
            color="var(--primary)"
            dark
            dense
            class="primary-btn-inner mr-3"
            @click="syncInfo()"
            >Import
          </v-btn>
          <v-btn
            color="var(--primary)"
            dark
            dense
            class="primary-btn-inner mr-3"
            @click="
              manageUserAction = 'Bulk Upload'
              showDocUpload = true
            "
            >Bulk Upload
          </v-btn>
          <v-btn
            color="var(--primary)"
            dark
            dense
            class="primary-btn-inner"
            @click="
              manageUserAction = 'Create'
              showClientIdDialog = true
            "
            >Create User
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredList"
      :items-per-page="10"
      :loading="loading"
      class="elevation-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <v-list-item v-for="action in actions" :key="action" link>
              <v-list-item-title @click="listAction(action, item)">{{
                action
              }}</v-list-item-title>
            </v-list-item> -->
            <!-- <v-list-item link>
              <v-list-item-title @click="listAction('Edit', item)"
                >Edit</v-list-item-title
              >
            </v-list-item> -->
            <v-list-item v-if="!item.is_active" link>
              <v-list-item-title @click="listAction('Activate', item)"
                >Activate</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-else link>
              <v-list-item-title @click="listAction('Deactivate', item)"
                >Deactivate</v-list-item-title
              >
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="listAction('Upload Single Doc', item)"
                >Upload Single Doc</v-list-item-title
              >
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="listAction('View Doc List', item)"
                >View Docs List</v-list-item-title
              >
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="listAction('Sync User Info', item)"
                >Sync User Info</v-list-item-title
              >
            </v-list-item>
            <v-list-item link v-if="!item.is_active">
              <v-list-item-title @click="listAction('Delete User', item)"
                >Delete User</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <span v-if="item.is_active">Active</span>
        <span v-else>Inactive</span>
      </template>
    </v-data-table>
    <ClientIdDialog
      :dialog.sync="showClientIdDialog"
      @createuser="clientInfoRetrieved()"
      @clientEmailEmpty="showClientEmailEmptyError = true"
    />
    <ManageUserDialog
      :dialog.sync="showManageUserDialog"
      :action="manageUserAction"
      :user="selectedUser"
      @refresh="getUserList()"
    />
    <ActivateDeactivateUserDialog
      :dialog.sync="showActivateDeactivateDialog"
      :action="manageUserAction"
      :user="selectedUser"
      @refresh="getUserList()"
    />
    <DeleteUserDialog
      :dialog.sync="showDeleteUserDialog"
      :user="selectedUser"
      @refresh="getUserList()"
    />
    <UploadSingleDocDialog
      :dialog.sync="showDocUpload"
      :action="manageUserAction"
      :user="selectedUser"
    />
    <ViewDocsList :dialog.sync="showDocsList" :user="selectedUser" />
    <ClientEmailMissingError :dialog.sync="showClientEmailEmptyError" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const ClientIdDialog = () => import('@/components/Dialog/clientIdDialog.vue')
const ManageUserDialog = () =>
  import('@/components/Dialog/ManageUsersDialog.vue')
const ActivateDeactivateUserDialog = () =>
  import('@/components/Dialog/activateDeactivateUserDialog.vue')
  const DeleteUserDialog = () =>
  import('@/components/Dialog/deleteUserDialog.vue')
const UploadSingleDocDialog = () =>
  import('@/components/Dialog/uploadSingleDoc.vue')
const ClientEmailMissingError = () =>
  import('@/components/Dialog/clientEmailMissingError.vue')
const ViewDocsList = () => import('@/components/Dialog/ViewDocsList.vue')

export default {
  components: {
    ClientIdDialog,
    ManageUserDialog,
    ActivateDeactivateUserDialog,
    DeleteUserDialog,
    UploadSingleDocDialog,
    ClientEmailMissingError,
    ViewDocsList,
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'client_name',
      },
      {
        text: 'UserID',
        align: 'start',
        sortable: true,
        value: 'user_id',
      },
      {
        text: 'Email ID',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Client ID',
        align: 'start',
        sortable: true,
        value: 'client_id',
      },
      {
        text: 'Contact number',
        align: 'start',
        sortable: false,
        value: 'phone_number',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'is_active',
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'action',
      },
    ],
    searchEmail: '',
    filterStatus: '',
    showManageUserDialog: false,
    showActivateDeactivateDialog: false,
    showDeleteUserDialog: false,
    showClientIdDialog: false,
    showDocUpload: false,
    manageUserAction: '',
    loading: false,
    selectedUser: {},
    showClientEmailEmptyError: false,
    showDocsList: false,
  }),
  computed: {
    ...mapGetters(['userList', 'isSuperAdmin']),
    filteredList() {
      let filteredUserList = []
      if (this.searchEmail !== '') {
        filteredUserList = this.userList.filter((user) => {
          return user.email.match(this.searchEmail)
        })
      } else {
        filteredUserList = this.userList
      }
      if (this.filterStatus !== '') {
        if (this.filterStatus === 'Active') {
          filteredUserList = filteredUserList.filter(
            (user) => user.is_active === true
          )
        } else if (this.filterStatus === 'Inactive') {
          filteredUserList = filteredUserList.filter(
            (user) => user.is_active === false
          )
        }
      }
      return filteredUserList
    },
  },
  methods: {
    async getUserList() {
      this.loading = true
      try {
        await this.$store.dispatch('get_user_list')
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    listAction(action, selectedUser) {
      if (action === 'Edit') {
        this.selectedUser = selectedUser
        this.manageUserAction = 'Edit'
        this.showManageUserDialog = true
      } else if (action === 'Activate' || action === 'Deactivate') {
        this.showActivateDeactivateDialog = true
        this.manageUserAction = action
        this.selectedUser = selectedUser
      } else if (action === 'Upload Single Doc') {
        this.showDocUpload = true
        this.manageUserAction = 'Single Upload'
        this.selectedUser = selectedUser
      } else if (action === 'Upload Multiple Docs') {
        this.showDocUpload = true
        this.manageUserAction = 'Multiple Upload'
        this.selectedUser = selectedUser
      } else if (action === 'View Doc List') {
        this.showDocsList = true
        this.selectedUser = selectedUser
      } else if (action === 'Sync User Info') {
        this.syncUserInfo(selectedUser)
      } else if (action === 'Delete User') {
        this.selectedUser = selectedUser
        this.showDeleteUserDialog = true
      }

    },
    async syncInfo() {
      this.loading = true
      try {
        await this.$store.dispatch('sync_info')
        this.$root.snackbar.show({
          message: 'Users synced successfully',
          color: 'success',
        })
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    async syncUserInfo(selectedUser) {
      this.loading = true
      try {
        let payload = {}
        payload.client_id = selectedUser.client_id
        await this.$store.dispatch('sync_user_info', payload)
        this.$root.snackbar.show({
          message: 'User synced successfully',
          color: 'success',
        })
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    clientInfoRetrieved() {
      this.manageUserAction = 'Create'
      this.showManageUserDialog = true
    },
  },
  mounted() {
    this.getUserList()
  },
}
</script>

<style lang="scss" scoped>
.row {
  align-items: center;
}

.table-headers {
  display: flex;
  flex-direction: column;

  .table-header-buttons {
    display: flex;
    flex-direction: column;

    .v-btn {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;

      .v-btn {
        margin-bottom: 0;
        flex: 1;
      }
    }

    @media screen and (min-width: 768px) {
    }
  }
}
</style>