var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-headers pa-4 elevation-2"},[_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search by email","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.searchEmail),callback:function ($$v) {_vm.searchEmail=$$v},expression:"searchEmail"}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('v-select',{attrs:{"items":['Active', 'Inactive'],"label":"Status","outlined":"","dense":"","clearable":"","hide-details":"auto"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"table-header-buttons",attrs:{"lg":"6","md":"6","cols":"12"}},[_c('v-btn',{staticClass:"primary-btn-inner mr-3",attrs:{"color":"var(--primary)","dark":"","dense":""},on:{"click":function($event){return _vm.syncInfo()}}},[_vm._v("Import ")]),_c('v-btn',{staticClass:"primary-btn-inner mr-3",attrs:{"color":"var(--primary)","dark":"","dense":""},on:{"click":function($event){_vm.manageUserAction = 'Bulk Upload'
            _vm.showDocUpload = true}}},[_vm._v("Bulk Upload ")]),_c('v-btn',{staticClass:"primary-btn-inner",attrs:{"color":"var(--primary)","dark":"","dense":""},on:{"click":function($event){_vm.manageUserAction = 'Create'
            _vm.showClientIdDialog = true}}},[_vm._v("Create User ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.filteredList,"items-per-page":10,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!item.is_active)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Activate', item)}}},[_vm._v("Activate")])],1):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Deactivate', item)}}},[_vm._v("Deactivate")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Upload Single Doc', item)}}},[_vm._v("Upload Single Doc")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('View Doc List', item)}}},[_vm._v("View Docs List")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Sync User Info', item)}}},[_vm._v("Sync User Info")])],1),(!item.is_active)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Delete User', item)}}},[_vm._v("Delete User")])],1):_vm._e()],1)],1)]}},{key:"item.is_active",fn:function(ref){
            var item = ref.item;
return [(item.is_active)?_c('span',[_vm._v("Active")]):_c('span',[_vm._v("Inactive")])]}}],null,true)}),_c('ClientIdDialog',{attrs:{"dialog":_vm.showClientIdDialog},on:{"update:dialog":function($event){_vm.showClientIdDialog=$event},"createuser":function($event){return _vm.clientInfoRetrieved()},"clientEmailEmpty":function($event){_vm.showClientEmailEmptyError = true}}}),_c('ManageUserDialog',{attrs:{"dialog":_vm.showManageUserDialog,"action":_vm.manageUserAction,"user":_vm.selectedUser},on:{"update:dialog":function($event){_vm.showManageUserDialog=$event},"refresh":function($event){return _vm.getUserList()}}}),_c('ActivateDeactivateUserDialog',{attrs:{"dialog":_vm.showActivateDeactivateDialog,"action":_vm.manageUserAction,"user":_vm.selectedUser},on:{"update:dialog":function($event){_vm.showActivateDeactivateDialog=$event},"refresh":function($event){return _vm.getUserList()}}}),_c('DeleteUserDialog',{attrs:{"dialog":_vm.showDeleteUserDialog,"user":_vm.selectedUser},on:{"update:dialog":function($event){_vm.showDeleteUserDialog=$event},"refresh":function($event){return _vm.getUserList()}}}),_c('UploadSingleDocDialog',{attrs:{"dialog":_vm.showDocUpload,"action":_vm.manageUserAction,"user":_vm.selectedUser},on:{"update:dialog":function($event){_vm.showDocUpload=$event}}}),_c('ViewDocsList',{attrs:{"dialog":_vm.showDocsList,"user":_vm.selectedUser},on:{"update:dialog":function($event){_vm.showDocsList=$event}}}),_c('ClientEmailMissingError',{attrs:{"dialog":_vm.showClientEmailEmptyError},on:{"update:dialog":function($event){_vm.showClientEmailEmptyError=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }